/* App.css */
.app {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f4f4f9;
}

.header {
  background-color: #fdf6f6;
  color: #000;
  padding: 1rem 2rem;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.header h1 {
  font-size: 2rem;
  margin-bottom: 10px;
}

.header nav {
  margin-top: 10px;
}

.header nav a {
  color: white;
  font-size: 1.1rem;
  margin: 0 15px;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease;
}

.header nav a:hover {
  color: #ff6600;
}

.main-content {
  display: flex;
  flex-wrap: wrap;
  padding: 30px;
  gap: 20px;
}

.top-stories {
  flex: 3;
  padding: 20px;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
}

.top-stories h2 {
  font-size: 1.8rem;
  margin-bottom: 20px;
  color: #333;
}

.story-card {
  margin-bottom: 30px;
  padding: 15px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.story-card img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 15px;
  transition: transform 0.3s ease;
  cursor: pointer;
}

.story-card img:hover {
  transform: scale(1.05);
}

.story-card h3 {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 10px;
}

.story-card p {
  color: #555;
  font-size: 1rem;
  line-height: 1.5;
}

.story-card a {
  display: inline-block;
  margin-top: 10px;
  color: #ff6600;
  font-weight: bold;
  text-decoration: none;
  transition: color 0.3s ease;
}

.story-card a:hover {
  color: #cc5200;
}

.time {
  font-size: 0.85rem;
  color: #888;
}

.featured-articles {
  flex: 2;
  padding: 20px;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
}

.featured-articles h3 {
  font-size: 1.6rem;
  margin-bottom: 20px;
  color: #333;
}

.featured-article {
  margin-bottom: 15px;
}

.featured-article a {
  font-size: 1.1rem;
  color: #333;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease;
}

.footer a {
  font-size: 1.1rem;
  color: #000;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease;
}

.featured-article a:hover, .footer a:hover {
  color: #ff6600;
}

.sidebar {
  width: 280px;
  padding: 20px;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
}

.sidebar h4 {
  font-size: 1.3rem;
  margin-bottom: 20px;
  color: #333;
}

form {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

form input {
  padding: 12px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
  transition: border-color 0.3s ease;
}

form input:focus {
  border-color: #ff6600;
  outline: none;
}

form button {
  padding: 12px;
  background-color: #ff6600;
  color: white;
  font-size: 1.1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

form button:hover {
  background-color: #cc5200;
}

footer {
  text-align: center;
  padding: 20px;
  background-color: #fdf6f6;
  color: #000;
  margin-top: 40px;
}

.loading,
.error {
  text-align: center;
  padding: 50px;
  font-size: 1.5rem;
}

/* Style for the Other Featured Articles section */
.sidebar ul {
  list-style-type: none; /* Removes bullet points */
  padding: 0; /* Removes default padding */
  margin: 0;
}

.sidebar ul li {
  margin-bottom: 15px; /* Adds spacing between list items */
}

.sidebar ul li a {
  display: block; /* Makes the link take up the full width of the container */
  font-size: 1.2rem; /* Makes the text larger */
  font-weight: bold; /* Makes the text bold */
  color: #333; /* Dark color for the text */
  text-decoration: none; /* Removes underline from the link */
  padding: 10px 15px; /* Adds padding around the link for better clickability */
  border-radius: 5px; /* Rounds the corners */
  transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transitions for hover effects */
}

.sidebar ul li a:hover {
  background-color: #ff6600; /* Adds a background color on hover */
  color: white; /* Changes text color to white on hover */
}

.sidebar ul li a:focus {
  outline: 2px solid #ff6600; /* Adds a visible outline when the link is focused */
  outline-offset: 4px; /* Adds some space around the outline */
}

.sidebar ul li a:active {
  background-color: #cc5200; /* Darker color when the link is clicked */
}

.page {
  max-width: 900px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.copy-right {
  text-align: center;
}

.hero {
  position: relative;
  text-align: center;
  color: white;
  background-color: transparent;
  padding: 50px 20px;
}

.hero-image {
  width: 100%;
  height: 500px;
  object-fit: cover;
  object-position: top;
  opacity: 0.7;
}

.hero-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.hero h2 {
  color: rgba(0, 0, 0, 0.5);
  font-size: 36px;
  font-weight: bold;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.hero p {
  font-size: 18px;
  max-width: 600px;
  margin: 0 auto;
}

.hero-btn {
  background-color: #ff6600;
  color: white;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 5px;
  margin-top: 20px;
}

.hero-btn:hover {
  background-color: #cc5200;
}

.logo {
  width: 160px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .hero h2 {
    font-size: 18px;
    color: #000;
  }
}